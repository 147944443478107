import Vue from "vue";

import "./styles/quasar.styl";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/ionicons-v4/ionicons-v4.css";
import {
  Quasar,
  QPageSticky,
  QCheckbox,
  Notify,
  QLayout,
  QHeader,
  QPageContainer,
  QPage,
  QBtn,
  QIcon,
  QFooter,
  QImg,
  QCard,
  QCardSection,
  QCardActions,
  QTooltip,
  QForm,
  QInput,
  QDialog,
  ClosePopup,
  Loading,
  QSeparator,
  Dialog,
  QSpace,
  QPagination,
  QTable,
  QTh,
  QTr,
  QTd,
  QBanner,
  QDrawer,
  QScrollArea,
  QBreadcrumbs,
  QBreadcrumbsEl,
  QCarousel,
  QCarouselControl,
  QCarouselSlide,
  QVideo,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QMenu,
  QBtnDropdown,
  QSelect,
  QAvatar,
  QTabPanels,
  QTabPanel,
  QTabs,
  QTab,
  QExpansionItem,
  QToolbar,
  QToolbarTitle,
  QMarkupTable,
  QBar,
  QTree,
  QStepper,
  QStep,
  QStepperNavigation,
  QPopupProxy,
  QDate,
  QRadio,
  QSkeleton
} from "quasar";

Vue.use(Quasar, {
  config: {
    loading: {}
  },
  components: {
    QLayout,
    QPageSticky,
    QCheckbox,
    QHeader,
    QPageContainer,
    QPage,
    QBtn,
    QIcon,
    QFooter,
    QImg,
    QTooltip,
    QCard,
    QCardSection,
    QCardActions,
    QForm,
    QInput,
    Notify,
    QDialog,
    ClosePopup,
    QSeparator,
    QSpace,
    QPagination,
    QTable,
    QTh,
    QTr,
    QTd,
    QBanner,
    QDrawer,
    QScrollArea,
    QBreadcrumbs,
    QBreadcrumbsEl,
    QCarousel,
    QCarouselControl,
    QCarouselSlide,
    QVideo,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QMenu,
    QBtnDropdown,
    QSelect,
    QAvatar,
    QTabPanels,
    QTabPanel,
    QTabs,
    QTab,
    QExpansionItem,
    QToolbar,
    QToolbarTitle,
    QMarkupTable,
    QBar,
    QTree,
    QStepper,
    QStep,
    QStepperNavigation,
    QPopupProxy,
    QDate,
    QRadio,
    QSkeleton
  },
  directives: {
    ClosePopup
  },
  plugins: {
    Notify,
    Loading,
    Dialog
  }
});
