import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    textBusqueda: "",
    modalBusqueda: false
  },
  mutations: {
    SET_TEXT_INPUT(state, data) {
      state.textBusqueda = data;
    },
    SET_MODAL_BUSCADOR(state, data) {
      state.modalBusqueda = data;
      if (data == false) {
        state.textBusqueda = "";
      }
    }
  },
  actions: {},
  modules: {}
});
