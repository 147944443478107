import Vue from "vue";
import axios from "axios";

let config = {
  baseURL: process.env.VUE_APP_API_PATH || "http://localhost:8000/api"
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // if (Store.state.auth_token || Store.state.auth_token !== "") {
    //   config.headers.Authorization = "Bearer " + Store.state.auth_token;
    // } else {
    //   if (
    //     localStorage.getItem("auth_token") != "" &&
    //     localStorage.getItem("auth_token") != null
    //   ) {
    //     config.headers.Authorization =
    //       "Bearer " + localStorage.getItem("auth_token");
    //   } else {
    //     config.headers.Authorization = "";
    //   }
    // }
    // Do something before request is sent
    config.headers.Authorization = "";
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    return Promise.reject(error);
    // if (
    //   error.response.config.url &&
    //   error.response.config.url.includes("login")
    // ) {
    //   return Promise.reject(error);
    // } else {
    //   if (error.response.status == 401) {
    //     if (error.response.data.error == "LOGIN_FAILED_EMAIL_NOT_VERIFIED") {
    //       //Cuenta sin verificar
    //       Store.dispatch("LOGOUT");
    //       Vue.axios.defaults.headers.common.Authorization = "";
    //       Router.push({ name: "login" });

    //       return Promise.reject(error);
    //     } else {
    //       if (error.response.data.error.code == "INVALID_TOKEN") {
    //         //Sesion expirada
    //         Store.dispatch("LOGOUT");
    //         Vue.axios.defaults.headers.common.Authorization = "";
    //         Router.push({ name: "login" });

    //         return Promise.reject(error);
    //       } else if (
    //         error.response.data.error.code == "AUTHORIZATION_REQUIRED"
    //       ) {
    //         //Debe iniciar sesion
    //         Store.dispatch("LOGOUT");
    //         Vue.axios.defaults.headers.common.Authorization = "";
    //         Router.push({ name: "login" });
    //         return Promise.reject(error);
    //       } else {
    //         Store.dispatch("LOGOUT");
    //         Vue.axios.defaults.headers.common.Authorization = "";
    //         Router.push({ name: "login" });
    //         return Promise.reject(error);
    //       }
    //     }
    //   } else {
    //     return Promise.reject(error);
    //   }
    // }
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
