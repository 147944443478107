







import { Component, Vue } from "vue-property-decorator";
import RedesSociales from "@/components/Common/RedesSociales.vue";

@Component({
  components: {
    RedesSociales
  }
})
export default class App extends Vue {}
